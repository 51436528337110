<template>
  <img class="chat-bot-icon" @click="$store.dispatch('ui/setChatBot',true)">
</template>

<script>

export default {
  name: 'ChatBotIcon'
};
</script>

<style scoped>
.chat-bot-icon {
  position: fixed;
  top: 80%;
  right: 20px;
  cursor: pointer;
  z-index: 3;
  opacity: 0.8;
  width: 50px;

  content:url('/assets/chatbot-icon-inactive.svg');
}

.chat-bot-icon:hover {
  content: url('/assets/chatbot-icon-dual-active.svg');
  opacity: 1;
}
@media (max-width: 575px) {
  .chat-bot-icon {
    top: 85%;
    right: 10px;
  }
}
</style>
